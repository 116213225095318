import React, {FunctionComponent} from 'react';
import {Box, styled, Typography} from "@mui/material";
import {contact} from "../../../data/data";

export type ContactInfoProps = {};

const ContactEntry = styled(Typography)({
    fontSize: '0.875rem',
    color: 'rgb(154, 154, 154)',
})

const bottomPadding = {
    paddingBottom: '20px'
}

const ContactInfo: FunctionComponent<ContactInfoProps> = ({}) => {

    return <Box>
        <ContactEntry>{contact.name}</ContactEntry>
        <ContactEntry sx={bottomPadding}>{contact.address[0].street}, {contact.address[0].zip}</ContactEntry>

        <ContactEntry>Telefon: {contact.phone[0]}</ContactEntry>
        <ContactEntry sx={{
            color: theme => theme.palette.common.black,
            fontWeight: 600,
            ...bottomPadding,
        }}>Email: {contact.email[0]}</ContactEntry>

        <ContactEntry>OIB: {contact.oib}</ContactEntry>
        <ContactEntry>Banka: Privredna banka Zagreb d.d.</ContactEntry>
        <ContactEntry>IBAN: {contact.iban}</ContactEntry>
        <ContactEntry>Upisana u Trg. sud u Zagrebu</ContactEntry>
        <ContactEntry sx={bottomPadding}>MBS (matični broj subjekta): {contact.mbs}</ContactEntry>

        <ContactEntry>Temeljni kapital: 3.090.000 kn,</ContactEntry>
        <ContactEntry>(3.070.000 kn u nekretnini)</ContactEntry>
        <ContactEntry sx={bottomPadding}>Osnivači: Ivan i Danijel Penić</ContactEntry>

        <ContactEntry>Direktori: Ivan Penić i Krešimir Ilić zastupaju</ContactEntry>
        <ContactEntry>samostalno i pojedinačno</ContactEntry>
    </Box>
};

export default ContactInfo;