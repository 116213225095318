import React, { FunctionComponent} from 'react';
import {Box, Container} from "@mui/material";

import Layout from "../../components/layout/Layout";
import ContactInfo from "../../components/contact/ContactInfo/ContactInfo";
import ContactForm from "../../components/contact/ContactForm/ContactForm";

export type ContactProps = {};

const Contact: FunctionComponent<ContactProps> = ({}) => {
    return <Layout title={'Kontakt'} description={'Kontakt informacije'}>
        <Container disableGutters maxWidth={'lg'} sx={{
            px: '15px',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                mx: '-15px',
            }}>
                <Box sx={{
                   px: '15px',
                    flexBasis: {
                       xs: '100%',
                       md: '50%',
                    },
                    '& > *': {
                       mb: '3rem'
                    }
                }}>
                    <ContactInfo />
                    <iframe width='100%' height='250' id="gmap_canvas"
                        src="https://maps.google.com/maps?q=gospodarska%208,%20pisarovina&t=&z=15&ie=UTF8&iwloc=&output=embed"
                        frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0} />
                </Box>
                <Box sx={{
                    px: '15px',
                    flexBasis: {
                        xs: '100%',
                        md: '50%',
                    },
                    pb: '3rem',
                }}>
                    <ContactForm />
                </Box>
            </Box>
        </Container>
    </Layout>
};

export default Contact;